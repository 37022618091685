const C = {
  SET_COMPANY: 'SET_COMPANY',
  SET_KEYWORD: 'SET_KEYWORD',
  FETCH_KEYWORDS: 'FETCH_KEYWORDS',
  FETCH_MOVIES_BY_COMPANY: 'FETCH_MOVIES_BY_COMPANY',
  FETCH_MOVIES_BY_KEYWORD: 'FETCH_MOVIES_BY_KEYWORD',
  FILTER_MOVIES_BY_RATING: 'FILTER_MOVIES_BY_RATING',
  FILTER_MOVIES_BY_APPROVAL: 'FILTER_MOVIES_BY_APPROVAL',
  START_FETCH: 'START_FETCH',
  END_FETCH_SUCCESS: 'END_FETCH_SUCCESS',
  END_FETCH_FAIL: 'END_FETCH_FAIL',
  CLEAR_KEYWORDS: 'CLEAR_KEYWORDS',
  API_KEY: process.env.API_KEY,
}

export default C;